import React from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import AppManager from "services/app-manager"
import Layout from "components/layout"
import { graphql, useStaticQuery } from "gatsby"
import { Typography } from "components/typography"
import { ListHeaderProvider } from "components/content/list-header"
import Faq from "components/content/faq"
import { chunkifyArray } from "components/utils"
import { MapToDiv } from "components/content/map-to-div"
import ProductCategoryPageTopSection from "components/content/product-category-page-top-section"
import { SectionTextDivider, SectionTextSubdivider } from "components/content/product-category-page-section-header"

const SavingsAccountsView = () => {
  const dateUpdated = AppManager.getInstance().getProductCategoryDateUpdated("sv")
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = dateUpdated ? dateUpdated.substring(8, 10) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4) : ""

  const isWide = useMediaQuery("(min-width:768px)")
  const chunkSize = isWide ? 4 : 2
  const features: any[] = [
    { name: "High Interest Savings Accounts", urlSlug: "high-interest-savings-accounts", img: "images/icon_best_rate.png" },
    { name: "Youth Savings Accounts", urlSlug: "youth-savings-accounts", img: "images/icon_savings_account.png" },
    { name: "Term Deposits", urlSlug: "term-deposits", img: "images/icon_term_deposit.png" },
    { name: "Everyday Bank Accounts", urlSlug: "bank-accounts", img: "images/icon_transaction_account.png" },
    // {name: "24+ month", urlSlug: "best-24-month-term-deposit-rates", img: "images/icon_term_deposit_24.png" },
  ]
  const featureLines = chunkifyArray(features, chunkSize)

  const svProviders: object = AppManager.getInstance().getReducedSavingsAccountProviders()
  const mainItems = Object.values(svProviders)
    .filter((x: any) => x.big4 && x.hasProduct)
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const otherItems = Object.values(svProviders)
    .filter((x: any) => !x.big4 && x.hasProduct)
    .sort((a: any, b: any) => (a.id > b.id ? 1 : -1))
  const majors = chunkifyArray(mainItems, chunkSize)
  const popular = chunkifyArray(otherItems, chunkSize)

  const queryResult = useStaticQuery(graphql`
    {
      allContentfulContentQa {
        nodes {
          slug
          qa {
            qa
          }
        }
      }
    }
  `)
  const qa = queryResult.allContentfulContentQa.nodes.find((x: any) => x.slug === "/savings-accounts")

  let maxRate = Number.MIN_VALUE
  let minMonthlyFee = Number.MAX_VALUE
  let minFxFee = Number.MAX_VALUE

  Object.values(svProviders).forEach((provider: any) => {
    if (provider.maxRate > maxRate) {
      maxRate = provider.maxRate
    }
    if (provider.minMonthlyFee < minMonthlyFee) {
      minMonthlyFee = provider.minMonthlyFee
    }
    if (provider.minFxFee < minFxFee) {
      minFxFee = provider.minFxFee
    }
  })

  let description = "Get a better High Interest Savings Account from your bank. Compare offers from across the market."
  if (minMonthlyFee === 0 && minMonthlyFee === 0) {
    description += " Pay no annual account fees."
  }
  description += " Earn up to " + (maxRate * 100).toFixed(2) + "% p.a. interest on your savings."

  return (
    <div>
      <div>
        <ProductCategoryPageTopSection productCategory="sv" dateString={dateString} pageHeading={`Compare savings accounts`} categoryDescription={description} />
      </div>
      <div>
        <div style={{ marginTop: "60px", marginBottom: "130px" }}>
          <SectionTextDivider heading="Compare savings accounts by feature" text="Compare savings accounts with high interest rates and other benefits." />
          <MapToDiv.Features features={featureLines} />
        </div>
        {/* <TopPicks /> */}
      </div>
      <div style={{ marginTop: "110px" }} id="providers">
        <SectionTextDivider heading="Compare leading savings account brands" text="Compare saving account interest rate offers from the major banks and other popular brands." />
        <SectionTextSubdivider subheading="Majors" text="Big banks with extensive nationwide networks that provide a comprehensive range of services" />
        <MapToDiv.Banks banks={majors} productCategory="savings-accounts" />
        <SectionTextSubdivider subheading="Challengers" text="Regional and international banking institutions that offer financial services" />
        <MapToDiv.Banks banks={popular} productCategory="savings-accounts" />
      </div>

      <div>
        {qa && (
          <div style={{ marginTop: "100px" }}>
            <ListHeaderProvider>{"FAQs"}</ListHeaderProvider>
            {/* {<Markdown md={qa.qa.qa} isArticle={true} />} */}
            <Faq faqContent={qa.qa.qa} />
          </div>
        )}
        {/* <TermDepositDisclaimer isDisclaimerOpen isProvider /> */}
      </div>
    </div>
  )
}

const SavingsAccounts = () => {
  const svProviders: object = AppManager.getInstance().getReducedSavingsAccountProviders()
  let maxRate = Number.MIN_VALUE
  let minMonthlyFee = Number.MAX_VALUE
  let minFxFee = Number.MAX_VALUE

  Object.values(svProviders).forEach((provider: any) => {
    if (provider.maxRate > maxRate) {
      maxRate = provider.maxRate
    }
    if (provider.minMonthlyFee < minMonthlyFee) {
      minMonthlyFee = provider.minMonthlyFee
    }
    if (provider.minFxFee < minFxFee) {
      minFxFee = provider.minFxFee
    }
  })

  let description = "Compare high interest savings accounts and the best savings interest rates from across the market."
  description += " Rates up to " + (maxRate * 100).toFixed(2) + "% p.a"
  if (minMonthlyFee === 0 && minFxFee === 0) {
    description += ", pay $0 annual fees"
  }
  description += "."

  return (
    <Layout title={`Compare High Interest Savings Accounts | Stay or Go`} description={description} image="/video/StayOrGo_Animation_01.jpf" urlSlug="savings-accounts" includeMozoDisclaimer>
      <div className="container-center">
        <div className="home-container">
          <SavingsAccountsView />
        </div>
      </div>
    </Layout>
  )
}

export default SavingsAccounts
